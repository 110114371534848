exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acalog-js": () => import("./../../../src/pages/acalog.js" /* webpackChunkName: "component---src-pages-acalog-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buyers-guide-js": () => import("./../../../src/pages/buyers-guide.js" /* webpackChunkName: "component---src-pages-buyers-guide-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-catalog-software-js": () => import("./../../../src/pages/course-catalog-software.js" /* webpackChunkName: "component---src-pages-course-catalog-software-js" */),
  "component---src-pages-curriculum-management-software-js": () => import("./../../../src/pages/curriculum-management-software.js" /* webpackChunkName: "component---src-pages-curriculum-management-software-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-program-of-studies-js": () => import("./../../../src/pages/program-of-studies.js" /* webpackChunkName: "component---src-pages-program-of-studies-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-smart-catalog-js": () => import("./../../../src/pages/smart-catalog.js" /* webpackChunkName: "component---src-pages-smart-catalog-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-student-handbook-software-js": () => import("./../../../src/pages/student-handbook-software.js" /* webpackChunkName: "component---src-pages-student-handbook-software-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-syllabus-management-software-js": () => import("./../../../src/pages/syllabus-management-software.js" /* webpackChunkName: "component---src-pages-syllabus-management-software-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-casestudy-js": () => import("./../../../src/templates/casestudy.js" /* webpackChunkName: "component---src-templates-casestudy-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

